



























































































































































































































































































































































































































































































import { Component, Watch, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppLogo from '@core/layouts/components/AppLogo.vue'
import {
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardHeader,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BCardBody,
  BCardFooter,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BButton
} from 'bootstrap-vue'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import { Action, Getter } from '@/store/decorators'
import AuthModule from '@/store/modules/authModule'
import { RegisterPayload } from '@/models/Auth'
import { User } from '@/models/User'
import SocialLogin from '@/views/auth/SocialLogin.vue'
import { required, email } from '@validations'
import {
  defaultProviderEquipment,
  defaultProviderLicences, defaultProviderServices,
  ProviderEquipmentItem,
  ProviderLicenceItem, ProviderServiceItem
} from '@/models/Provider'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

@Component({
  name: 'WashirPro',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    SocialLogin,
    AppLogo,
    // validations
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility]
})
export default class WashirPro extends Vue {
  @Action(AuthModule, 'registerProvider') private registerProvider!: (payload: RegisterPayload) => Promise<User>
  @Getter(AuthModule, 'activeUser') private activeUser!: User
  $refs!: { registerWashirProForm }

  private mounted () {
    const rules = [email, required]
    Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
  }

  private appName: string = $themeConfig.app.appName
  private status: string = ''
  private firstName: string = ''
  private lastName: string = ''
  private proPhone: string = ''
  private userEmail: string = ''
  private sideImg: string = require('@/assets/images/pages/register-v2.svg')
  private passwordFieldType: string = 'password'
  private password: string = 'password'
  private passwordConfirmation: string = 'password'
  private passwordConfirmationFieldType: string = 'password'
  private isTermsConditionAccepted: boolean | null = null
  private providerLicences: ProviderLicenceItem[] = defaultProviderLicences()
  private providerEquipment: ProviderEquipmentItem[] = defaultProviderEquipment()
  private providerServices: ProviderServiceItem[] = defaultProviderServices()

  private destroyed () {
    this.$destroy()
  }

  private register () {
    this.$refs.registerWashirProForm.validate().then(success => {
      if (success) {
        this.handleRegisterUser()
      }
    })
  }

  private async handleRegisterUser () {
    await this.$recaptchaLoaded()
    const token = await this.$recaptcha('contact')
    if (token) {
      // this.form['g-recaptcha-response'] = token
      // action goes here
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'Data has been saved.',
          variant: 'primary'
        }
      })
      // end of action
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'recaptcha error',
          variant: 'danger'
        }
      })
    }
  }

  get passwordConfirmToggleIcon () {
    return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  }

  get passwordToggleIcon () {
    return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  }
}
